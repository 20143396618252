<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        min?: number;
        max?: number;
        step?: number;
        mask: _VTI_TYPE_Function;
        unmask: _VTI_TYPE_Function;
        setter?: _VTI_TYPE_Function;
    }
    import { FormInputData } from '~~/composables/useFormInput'
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: { default: 0.01 },
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  min: { default: 0 },
  max: { default: 1000000 },
  mask: null,
  unmask: null,
  setter: null
});
    const inputData: FormInputData = useFormInput(props);
    const focused: Ref<boolean> = ref(false);
    const internalModel = ref<string | number>(0);
    const focusOut = async () => {
        console.log('focusOut', {
            internalModel: internalModel.value,
            model: inputData.model.value,
        });
        focused.value = false;
        await nextTick();
        internalModel.value = props.mask(inputData.model.value);
    };
    const focusIn = async () => {
        console.log('focusIn', {
            internalModel: internalModel.value,
            model: inputData.model.value,
        });
        focused.value = true;
        await nextTick();
        internalModel.value = props.unmask(inputData.model.value);
    };
    const onChange = () => {
        console.log('onChange', {
            internalModel: internalModel.value,
            model: inputData.model.value,
        });
        if (props.setter) {
            inputData.model.value = props.setter(internalModel.value);
            return;
        }
        inputData.model.value = parseFloat(internalModel.value);
    };
    // Watch the inputData.model value and update the input value manually
    // This is needed because the FormattedNumberInput is not using it directly.
    watch(inputData.model, (value) => {
        if (focused.value) {
            return;
        }
        internalModel.value = props.mask(value);
    });
    onMounted(() => {
        internalModel.value = props.mask(inputData.model.value);
    });
</script>

<template>
    <FormElement :input-data="inputData">
        <input
            :id="inputData.inputId.value"
            v-model="internalModel"
            :class="inputData.className.value"
            :name="inputData.props.inputName"
            :placeholder="inputData.placeholder.value"
            :min="min"
            :max="max"
            :step="step"
            :type="focused ? 'number' : 'text'"
            :disabled="inputData.props.disabled"
            role="presentation"
            autocomplete="off"
            @focus="focusIn"
            @blur="focusOut"
            @change="onChange"
        />
    </FormElement>
</template>
